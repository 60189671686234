<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">

    <v-row no-gutters>
      <!--v-col cols="12" style="height: 250px">
        <Adsense
          style="display:block"
          data-ad-client="ca-pub-3840780747315819"
          data-ad-slot="7738093838"
          data-ad-format="auto"
          data-full-width-responsive="true"
          data-ad-test="on">
        </Adsense>
      </v-col--->

      <!-- Toggle buttons -->
      <v-col cols="12" class="mt-2">
        <v-row justify="center" no-gutters class="mx-2">

          <v-col cols="4">
            <v-card
              @click="switchTabMainPanel(0)"
              height="60"
              outlined
              tile
              style="border-top-left-radius: 10px !important;border-bottom-left-radius: 10px !important;border-right: 1px solid #4D59FF;border-left: 2px solid #4D59FF; border-bottom: 2px solid #4D59FF;border-top: 2px solid #4D59FF;"
              :class="tabMainPanel === 0 ? 'card-switch-transports-active' : 'card-switch-transports'"
            >
              <p
                class="text-center my-1"
                :class="tabMainPanel === 0 ? 'card-switch-transports-text-active' : 'card-switch-transports-text'"
                v-html="$t('view.modes.tabs.transports')" />
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card
              @click="switchTabMainPanel(1)"
              height="60"
              outlined
              tile
              style="border-right: 1px solid #4D59FF !important;border-left: 1px solid #4D59FF !important;border-top: 2px solid #4D59FF !important;border-bottom: 2px solid #4D59FF !important"
              :class="tabMainPanel === 1 ? 'card-switch-transports-active' : 'card-switch-transports'"
            >
              <p
                class="text-center my-1"
                :class="tabMainPanel === 1 ? 'card-switch-transports-text-active' : 'card-switch-transports-text'"
                v-html="$t('view.modes.tabs.micromobility')"
              />
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card
              @click="switchTabMainPanel(2)"
              height="60"
              outlined
              tile
              :class="tabMainPanel === 2 ? 'card-switch-transports-active' : 'card-switch-transports'"
              style="border-top-right-radius: 10px !important;border-bottom-right-radius: 10px !important;border-left: 1px solid #4D59FF;border-right: 2px solid #4D59FF; border-bottom: 2px solid #4D59FF;border-top: 2px solid #4D59FF;"
            >
              <p
                class="text-center my-1"
                :class="tabMainPanel === 2 ? 'card-switch-transports-text-active' : 'card-switch-transports-text'"
                v-html="$t('view.modes.tabs.PoI')"
              />
            </v-card>
          </v-col>

        </v-row>
      </v-col>

      <!-- Normal transports -->
      <v-col cols="12" v-if="tabMainPanel === 0" class="mt-4">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="10">
                <h3 class="font-weight-bold">{{$t('view.modes.titles.transports')}}</h3>
              </v-col>
              <div>
                <v-switch color="primary" v-model="showPublicTransportation" ></v-switch>
              </div>
            </v-row>

          </v-col>

          <v-col cols="12">
            <v-row class="custom-scrollbar" justify="start" no-gutters style="overflow-x: auto !important; flex-wrap: nowrap !important; height: 100px !important;">
              <template v-for="(transport) of transports">
                <v-col cols="auto" style="margin-top: 5px">
                  <IconMode
                    :mode="transport"/>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Alt transports -->
      <v-col cols="12" v-if="tabMainPanel === 1" class="mt-4">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="10">
                <h3 class="font-weight-bold">{{$t('view.modes.titles.transports_alt')}}</h3>
              </v-col>
              <div>
                <v-switch color="primary" v-model="showAlternativeTransportation"></v-switch>
              </div>
            </v-row>

          </v-col>

          <v-col cols="12" >
            <v-row class="hide-scrollbar" justify="start" no-gutters style="overflow-x: auto !important; flex-wrap: nowrap !important; height: 100px !important;">
              <template v-for="(transport) of transports_alt">
                <v-col cols="auto" style="margin-top: 5px">
                  <IconMode
                    :mode="transport"
                    />
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Points of interest -->
      <v-col cols="12" v-if="tabMainPanel === 2" class="mt-4">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="10">
                <h3 class="font-weight-bold">{{$t('view.modes.titles.PoI')}}</h3>
              </v-col>
              <div>
                <v-switch color="primary" v-model="showPointOfInterest"></v-switch>
              </div>
            </v-row>

          </v-col>

          <v-col cols="12">
            <v-row class="hide-scrollbar" justify="start" no-gutters style="overflow-x: auto !important; flex-wrap: nowrap !important; height: 100px !important;">
              <template v-for="(transport) of points_of_interest">
                <v-col cols="auto" style="margin-top: 5px">
                  <IconMode
                    v-if="transport.disabled !== true"
                    :mode="transport"
                    />
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Favorites -->
      <v-col cols="12" class="mt-6">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="space-between">
              <h3 class="font-weight-bold">{{$t('view.favorites.title')}}</h3>
              <div style="font-size: 14px;" v-if="$session.exists()">
                <router-link class="text-decoration-none primary-text-color" :to="{name: 'MyAddresses'}">Voir plus</router-link>
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="$session.exists() && !favoritesLoaded">
            <Skeleton
              :occurrence="3"
              type="list-item" />
          </v-col>

          <v-col cols="12" v-else-if="$session.exists() && favoritesLoaded">
            <p v-if="favorites.length === 0">{{$t('view.favorites.no-result')}}</p>

            <v-card class="my-2" style="border-radius: 10px !important;" v-if="favorites.length > 0">
              <v-list>
                <template v-for="(el) of favorites">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-if="el.type === 'HOME'" color="primary" size="20">far fa-home</v-icon>
                      <v-icon v-else-if="el.type === 'WORK'" color="primary" size="20">far fa-briefcase</v-icon>
                      <v-icon v-else color="primary" size="20">far fa-star</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="cursor: pointer !important;" @click="goToCoordinates(el.coordinates)">{{el.address}}</v-list-item-title>
                      <v-list-item-subtitle v-if="!['HOME', 'WORK'].includes(el.type)">{{el.label}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon elevation="2" class="mr-1" @click="goToItinerary(el.address, el.coordinates)">
                        <img src="@/assets/icons/controls/go_btn.svg" width="20" class="ml-1"/>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>

          <!-- Not connected : -->
          <v-col cols="12" v-else class="text-center">
            <v-btn
              :to="{name: 'Registration'}"
              color="primary"
              style="width: 120px; text-transform: none !important;">S'inscrire
            </v-btn>
          </v-col>

        </v-row>
      </v-col>

      <!--v-col cols="12" class="mt-4">
        <v-divider   />
      </v-col-->

      <!-- History -->
      <v-col cols="12" class="mt-6">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters justify="space-between">
              <h3 class="font-weight-bold">{{$t('view.history.title')}}</h3>
              <div style="font-size: 14px" v-if="$session.exists()">
                <router-link class="text-decoration-none primary-text-color" :to="{name: 'MyHistory'}">Voir plus</router-link>
              </div>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="$session.exists() && !historyLoaded">
            <Skeleton
              :occurrence="3"
              type="list-item" />
          </v-col>

          <v-col cols="12" v-else-if="$session.exists() && historyLoaded">
            <p v-if="history.length === 0">{{$t('view.history.no-result')}}</p>
            <v-card class="my-2" style="border-radius: 10px !important;" v-if="history.length > 0">
              <v-list>
                <template v-for="(el) of history">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary" size="20">far fa-search</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title style="cursor: pointer !important;" @click="goToCoordinates(el.coordinatesTo)">{{el.addressTo}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon elevation="2" class="mr-1" @click="goToItinerary(el.addressTo, el.coordinatesTo)">
                      <img src="@/assets/icons/controls/go_btn.svg" width="20" class="ml-1"/>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-col>

          <!-- Not connected : -->
          <v-col cols="12" v-else class="text-center">
            <v-btn
              :to="{name: 'Registration'}"
              color="primary"
              style="width: 120px; text-transform: none !important;">S'inscrire
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <WalletWeb3 />

    <AdsSquare />
    <Footer />

  </v-container>
</template>

<script>
import modes from "../constants/modes.json"

export default {

  name: "MainPanel",

  components: {
    WalletWeb3: () => import("@/components/Common/WalletWeb3"),
    AdsSquare: () => import("@/components/Common/AdsSquare"),
    Footer: () => import("@/components/Common/Footer"),
    Skeleton: () => import("@/components/Common/Skeleton"),
    IconMode: () => import("@/components/Common/IconMode"),
    MyFavoriteCard: () => import("@/components/Account/MyFavoriteCard"),
    MyHistoryCard: () => import("@/components/Account/MyHistoryCard")
  },

  data() {
    return {
      tabMainPanel: 0,
      modes,

      favoritesLoaded: false,
      historyLoaded: false,

      favorites: [],
      history: [],
    }
  },

  created() {
    if (this.$session.exists()) {
      this.getFavorites();
      this.getHistory();
    }

    this.$store.commit("pageHead/setTitle", this.$t('view.pageTitle'));
  },

  methods: {
    getFavorites() {
      this.favoritesLoaded = false;
      this.$http
      .get(`/map/users/${this.$session.get('id')}/favorites?limit=5`, {
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      })
      .then((res) => {
        this.favorites = res.data.items;
      })
      .catch(err => {
        // TODO : Error handler
      })
      .finally(() => {
        this.favoritesLoaded = true;
      });
    },

    getHistory() {
      this.historyLoaded = false;
      this.$http
      .get(`/map/users/${this.$session.get('id')}/search-history?limit=5&sortBy=meta.created.date&sortDesc=true`, {
        headers: {
          Authorization: `Bearer ${this.$session.get('jwt')}`
        }
      })
      .then((res) => {
        this.history = res.data.items;
      })
      .catch(err => {
        // TODO : Error handler
      })
      .finally(() => {
        this.historyLoaded = true;
      })
    },

    switchTabMainPanel(mainPanel) {
      /*
      if (mainPanel === 0)
        this.$store.dispatch("modes/switchMainTab", "TRANSPORTS");
      else
        this.$store.dispatch("modes/switchMainTab", "POI");

      this.getTransportsAround();*/
      this.tabMainPanel = mainPanel;
      //this.$store.dispatch("modes/updateSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
    },

    getTransportsAround () {
      this.$store.dispatch("modes/getTransportsAround", {
        modes: this.$store.getters["modes/getEnabled"],
        keepOldData: false,
        jwt: this.$session.get('jwt'),
        messages: {
          ERROR_MODE_NOT_LOADED: this.$t('viewer.errors.MODES_NOT_LOADED')
        }
      });
    },

    goToItinerary(label, coordinates) {

      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, fromLabel: "Ma position", from: `${position.coords.latitude},${position.coords.longitude}`, toLabel: label, to: `${coordinates.lat},${coordinates.lng}`}});
      }, (error) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, toLabel: label, to: `${coordinates.lat},${coordinates.lng}`}});
      });
    },

    goToCoordinates(coordinates) {
      this.$router.replace({query: {...this.$route.query, coords: `${coordinates.lat},${coordinates.lng}`}});
    }
  },

  computed: {
    transports() {
      return this.modes.filter(el => el.category === 'transports');
    },

    transports_alt() {
      return this.modes.filter(el => el.category === 'transports_alt');
    },

    points_of_interest() {
      return this.modes.filter(el => el.category === 'points_of_interest');
    },

    showPublicTransportation: {
      get() {
        return this.$store.getters["modes/isAllPublicTransportationEnabled"];
      },
      set(val) {
        this.$store.dispatch("modes/togglePublicTransportation", val);
        if (val)
          this.getTransportsAround();
        this.$store.dispatch("modes/updateSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
      }
    },

    showAlternativeTransportation: {
      get() {
        return this.$store.getters["modes/isAllAlternativeTransportationEnabled"];
      },
      set(val) {
        this.$store.dispatch("modes/toggleAlternativeTransportation", val);
        if (val)
          this.getTransportsAround();
        this.$store.dispatch("modes/updateSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
      }
    },

    showPointOfInterest: {
      get() {
        return this.$store.getters["modes/isAllPointOfInterestEnabled"];
      },
      set(val) {
        this.$store.dispatch("modes/togglePointOfInterest", val);
        if (val)
          this.getTransportsAround();
        this.$store.dispatch("modes/updateSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
      }
    },

    switchColor() {
      return this.showAlternativeTransportation ? 'primary' : '#FF8765';
    },
  }
}
</script>

<style scoped>
  .card-switch-transports {
    background-color: transparent !important;
  }

  .card-switch-transports-active {
    background-color: #4D59FF !important;
  }

  .card-switch-transports-text {
    color: #4D59FF !important;
  }

  .card-switch-transports-text-active {
    color: white !important
  }

  ::-webkit-scrollbar > .custom-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track > .custom-scrollbar {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb > .custom-scrollbar {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover > .custom-scrollbar{
    background: #555;
  }
</style>
